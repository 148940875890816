<template>
    <div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-32 lg:px-32">
        <div class="h-28 w-28 bg-white rounded-full shadow-md mx-auto p-5 z-10">
            <img src="@/assets/images/password.svg" />
        </div>

        <form class="rounded-xl shadow-md bg-white p-5 space-y-7 pt-20 -mt-12" @submit="submitGantiPassword()">
            <custom-input id="old_password" type="password" label="Password Lama" v-model="mainForm.old_password" :errorMessage="errorForm.old_password"/>
            <custom-input id="new_password" type="password" label="Password Baru" v-model="mainForm.new_password" :errorMessage="errorForm.new_password"/>
            <custom-input id="confirm_password" type="password" label="Konfirmasi Password" v-model="mainForm.confirm_password" :errorMessage="errorForm.confirm_password"/>
            <div>
                <button type="submit"
                @click="submitGantiPassword()" 
                :disabled="isLoading"
                class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-lg rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg class="h-5 w-5 text-blue-500 group-hover:text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                        </svg>
                    </span>
                    Ganti Password
                </button>
            </div>
        </form>
        <loading-modal :isShow="isLoading" />
    </div>
</template>
<script>
    import CustomInput from '../../components/CustomInput.vue';
	import LoadingModal from '../../components/LoadingModal.vue';
    import {showSuccessNotif, showErrorNotif, extractValidationError, errorHandler} from '../../helpers/Tools';
    
    export default {
        name: 'ProfilPassword',
        components : {
			CustomInput, LoadingModal
        },
        data () {
            return {
				isLoading : false,
                mainForm: {
                    old_password    : '',
                    new_password    : '',
                    confirm_password: '',
                },
                errorForm : {
                    old_password    : '',
                    new_password    : '',
                    confirm_password: '',
                },
            }
        },
        methods : {
			async submitGantiPassword () {
                this.isLoading = true;

                try {
                    this.errorForm = {
                        old_password    : '',
                        new_password    : '',
                        confirm_password: '',
					};

					let res = await this.$store.dispatch("postGantiPassword", this.mainForm);
					let result = res.data;
					this.isLoading = false;
					if(result.status == 'success') {
						showSuccessNotif(this, result.message);
                        this.mainForm = {
                            old_password    : '',
                            new_password    : '',
                            confirm_password: '',
                        }
					} else {
                        this.errorForm = extractValidationError(this.errorForm, result.data);
					}

                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },
        }
    }
</script>
